import { Container, Grid } from "@mui/material";

import InfoCard from "../Components/InfoCard";
import MeetingCard from "../Components/MeetingCard";
import TopImage from "../Components/TopImage";

export default function About() {
    return (
        <>
            <TopImage image="/assets/homepageBanner.webp" alt="About Header" />
            <Container maxWidth="lg" sx={{ "marginTop": "24px", marginBottom: "24px" }}>
                <Grid container spacing={3}>
                    <MeetingCard />
                    <InfoCard title="What do we do?"
                        body="We are a club that focuses on technology, and we plan to work on many projects to learn and help others as well. We do projects such as learning clustering to build a distributed Minecraft server, repairing old computers, and many other random things. Basically, we do anything under the sun that falls under the category of computers."
                    />
                    <InfoCard title="How can I join?"
                        body="You can join by coming to our metings, the next date of which is above, and you can also join our Discord server to get notified of them and talk to other club members! We are always looking for new members, so don't be shy!"
                        buttonText="Join our Discord"
                        link="/discord"
                    />
                    {/*
                    <InfoCard
                        alt="Collage of computer club related things"
                        imgLocation="left"
                        title="About Club"
                        body="Our mission is to bring together people interested in technology, whether they don't know much about it, or a lot. We plan to work on many projects to learn and help others as well."
                        image="/assets/ComputerClubCollage.webp"
                    />
                    */}
                    <InfoCard
                        alt="Collage of computer club related things"
                        imgLocation="left"
                        title="About Leadership"
                        body="We are Ian, Jonathan, and Jagger, with club sponsor Mr. Stone. We have always liked technology and messing with it, and thought, why not start a club for others like us? 
                        And well, that's about it."
                        image="/assets/1080p/ComputerClubCollage.webp"
                    />
                </Grid>
            </Container>
        </>
    )
}