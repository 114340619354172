import { Button, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";


export default function NotFound() {
    const navigate = useNavigate();
    const staticCanvas = React.createRef<HTMLCanvasElement>();
    React.useEffect(() => {
        const canvas = staticCanvas.current;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            resize(staticCanvas.current);
            if (ctx) {
                (function loop() {
                    noise(ctx);
                    requestAnimationFrame(loop);
                })();
            }
        }
    }, [staticCanvas]);
    function resize(canvas: HTMLCanvasElement) {
        canvas.width = (window.innerWidth / 1.5);
        canvas.height = (window.innerHeight / 1.5);
    }
    function noise(ctx: CanvasRenderingContext2D) {

        var w = ctx.canvas.width,
            h = ctx.canvas.height,
            idata = ctx.createImageData(w, h),
            buffer32 = new Uint32Array(idata.data.buffer),
            len = buffer32.length,
            i = 0;

        for (; i < len;)
            buffer32[i++] = ((255 * Math.random()) | 0) << 24;

        ctx.putImageData(idata, 0, 0);
    }

    return (
        <>
            <Container sx={{ paddingTop: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Card>
                    <CardContent style={{ textAlign: "center", padding: "32px" }}>
                        <Typography variant="h3" style={{ fontFamily: "'VCR OSD Mono', monospace" }}>Huh?</Typography>
                        <Typography variant="body1" paddingTop="16px">We searched far and wide, but couldn't find that page. Check your spelling, or if a link lead you here, tell us.</Typography>
                        <Grid container spacing={3} justifyContent="center" paddingTop="16px">
                            <Grid item>
                                <Button variant="outlined" component={Link} to="/">Home</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={() => navigate(-1)}>Back</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <canvas ref={staticCanvas} style={{ zIndex: "-1", background: "white", position: "fixed", top: 0, left: 0, height: "100vh", width: "100vw" }}></canvas>
            </Container>
        </>
    )
}