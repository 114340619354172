
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import RedirectUrl from "./Components/RedirectURL";
import ScrollToTop from "./Components/ScrollToTop";
import TopBar from "./Components/TopBar";
import About from "./Pages/About";
import Donations from "./Pages/Donations";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import NotFound from "./Pages/NotFound";
import TechHelp from "./Pages/TechHelp";
let theme = createTheme({
    palette: {
        primary: {
            main: "#16E700",
        },
        secondary: {
            main: "#fff",
        },
    },
    components: {
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#101010",
                    color: "white",
                    fontFamily: "'VCR OSD Mono', monospace",
                    textTransform: "uppercase"
                },

            },
        },
        // Name of the component
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    fontFamily: "'VCR OSD Mono', monospace",
                },
                // Name of the slot
                outlined: {
                    "&:hover": {
                        borderWidth: "4px"
                    },
                    fontFamily: "'VCR OSD Mono', monospace",
                    fontWeight: "bold",
                    color: "white",
                    borderWidth: "4px",
                    borderColor: "#16E700",
                    paddingLeft: "3rem",
                    paddingRight: "3rem"
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: "#101010",
                    borderRadius: "32px",
                    color: "white",
                },
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    fontFamily: "'VCR OSD Mono', monospace",
                    fontWeight: "bold",
                },
            }
        }
    },
});

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <div>
                    <ScrollToTop /> {/* for whatever stupid reason, react router doesn't automatically scroll to top when you visit a new page, so this implements that*/}
                    <TopBar />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/techHelp" element={<TechHelp />} />
                        <Route path="/donations" element={<Donations />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/discord" element={<RedirectUrl url="https://discord.gg/Ry3dJ6XBPv" />} />
                        <Route path="/404" element={<NotFound />} />
                        <Route path="/*" element={<NotFound />} />
                    </Routes>
                </div>
            </ThemeProvider >
        </BrowserRouter>
    );
}

export default App;
