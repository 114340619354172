import { Button, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";


export default function Login() {
    const navigate = useNavigate();
    return (
        <>
            <Container sx={{ paddingTop: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Card>
                    <CardContent style={{ textAlign: "center", padding: "32px" }}>
                        <Typography variant="h3" style={{ fontFamily: "'VCR OSD Mono', monospace" }}>Login Not Implemented</Typography>
                        <Typography variant="body1" paddingTop="16px">This feature has not been implemented yet. Sorry about that!</Typography>
                        <Grid container spacing={3} justifyContent="center" paddingTop="16px">
                            <Grid item xs={12}>
                                <Button variant="outlined" component={Link} to="https://int.chscompclub.com">Visit Internal Dashboard</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" component={Link} to="/">Home</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={() => navigate(-1)}>Back</Button>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </>
    )
}