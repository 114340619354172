import { Card, CardHeader, Container, Grid } from "@mui/material";

import InfoCard from "../Components/InfoCard";
import MeetingCard from "../Components/MeetingCard";
import TopImage from "../Components/TopImage";


function FollowUsCard() {
    return (
        <Grid item sx={{ width: "100%" }}>
            <Card>
                <CardHeader
                    sx={{ color: "white", textAlign: "center", textTransform: "uppercase" }}
                    title={
                        <>
                            Follow @chscompclub on <a style={{ color: "white" }} href="https://youtube.com/@chscompclub">YouTube</a> and <a style={{ color: "white" }} href="https://instagram.com/chscompclub">Instagram!</a>
                        </>
                    }
                />
            </Card>
        </Grid>
    )
}
export default function Home() {
    return (
        <>
            <TopImage image="/assets/homepageBanner.webp" alt="Cary High Computer Club: Coming School Year 2023-24" />
            <Container maxWidth="lg" sx={{ "marginTop": "24px", marginBottom: "24px" }}>
                <Grid container spacing={3}>
                    <MeetingCard />
                    <FollowUsCard />
                    <InfoCard
                        alt="Collage of computer club related things"
                        imgLocation="left"
                        title="About Us"
                        body="We are a group of passionate students who love technology and want to learn and help others. We meet about every Wendesday after school in room 2318. Come by during lunch too, we will likely be there. Click below to learn more!"
                        buttonText="Learn More"
                        image="/assets/1080p/ComputerClubCollage.webp"
                        link="/about"
                    />
                    <InfoCard
                        alt="Drake helps Lil Yachty with a laptop meme"
                        imgLocation="right"
                        title="Need Tech Help?"
                        body="Having trouble with technology? We're here to help. Or madly smash the keyboard like you have been doing for the past hour."
                        buttonText="Yes, Please!"
                        image="/assets/drakeLaptop.webp"
                        link="/techHelp"
                    />
                    <InfoCard
                        alt="Picture of old hardware"
                        imgLocation="left"
                        title="Wanna Donate?"
                        body="We're happy to accept your old computers or devices, and you will be happy knowing it's going to a public school.
                        Also, can't you claim a tax deduction or something? I guess that's a win-win."
                        buttonText="Give Us Tech"
                        image="/assets/oldHardware.webp"
                        link="/donations"
                    />
                </Grid>
            </Container>
        </>
    )
}