import "../Styles/InfoCards.scss";

import { Button, Card, Grid } from "@mui/material";
import { Link } from "react-router-dom";

export default function InfoCard(props: { alt?: string, imgLocation?: "right" | "left", title: string, body: string, buttonText?: string, image?: string, link?: string }) {

    return (
        <Grid item>
            <Card sx={{ backgroundColor: "#101010", padding: "1rem", overflow: "initial" }}>
                <Grid container spacing={2} style={{ flexDirection: props.imgLocation === "right" ? "row-reverse" : "row" }}>
                    {props.imgLocation && props.image ? <>{/*if image and img location props are defined, show the image */}
                        <Grid item sm={6}>
                            <div className={`infocard-outerbox infocard-outerbox-${props.imgLocation}`}>
                                <img alt={props.alt} className={`infocard-img-inner infocard-img-inner-${props.imgLocation}`} src={props.image} />
                            </div>
                        </Grid>
                    </> : <></>}
                    <Grid item sm={props.image && props.imgLocation ? 6 : 12} className="infocard-text">
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <h1 style={{ textTransform: "uppercase", fontFamily: "'VCR OSD Mono', monospace", color: "white", marginTop: 0 }}>
                                {props.title}
                            </h1>
                            <p style={{ color: "white" }}>
                                {props.body}
                            </p>
                            {props.link && props.buttonText ? <Button variant="outlined" component={Link} to={props.link}>
                                {props.buttonText}
                            </Button> : null}
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
}