import { Card, CardContent, CardHeader, Container, Grid } from "@mui/material";

import GoogleFormEmbedCard from "../Components/GoogleFormEmbedCard";
import TopImage from "../Components/TopImage";

export default function TechHelp() {
    return (
        <>
            <TopImage image="/assets/homepageBanner.webp" alt="Tech Help Header" />
            <Container maxWidth="lg" sx={{ "marginTop": "24px", marginBottom: "24px" }}>
                <Grid container spacing={3}>
                    <Grid item sx={{ width: "100%" }}>
                        <Card sx={{ borderRadius: "32px" }}>
                            <CardHeader
                                sx={{ color: "white", textAlign: "center", textTransform: "uppercase" }}
                                title="Before you ask for help, have you..."
                            />
                            <CardContent sx={{ color: "white", textAlign: "center", paddingTop: 0 }}>
                                <ul style={{ listStylePosition: "inside", padding: 0 }}>
                                    <li>Restarted?</li>
                                    <li>Checked the power?</li>
                                    <li>Checked for & ran updates?</li>
                                </ul>
                            </CardContent>
                        </Card>
                    </Grid>
                    <GoogleFormEmbedCard title="Tech Help Form" formLink="https://docs.google.com/forms/d/e/1FAIpQLSf4lDo-T0AYq8kbF_5zY2E4wcieghw8hKygDykXK_l_DP3pRg/viewform" />
                </Grid>
            </Container>
        </>
    )
}