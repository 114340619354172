import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link } from "react-router-dom";

const pages = [{ title: "Tech Help", link: "/techHelp" }, { title: "Donations", link: "/donations" }, { title: "About", link: "/about", out: false, }, { title: "Internal Club Login", link: "/login", out: false, }];

function TopBar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="static" style={{ backgroundColor: "#000" }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
                        <img src="/assets/icon.webp" style={{ maxHeight: "2rem" }} alt="CHS Computer Club logo" />
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                            display: { xs: "none", md: "flex" },
                            fontFamily: "VCR OSD Mono",
                            color: "white",
                            textDecoration: "none",
                            textTransform: "uppercase"
                        }}
                    >
                        Cary High Computer Club
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
                    {/* Below is mobile navbar*/}
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="secondary"
                        >
                            <MenuIcon />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" }
                            }}
                        >
                            {pages.map((page) => (
                                <>
                                    {page.out ? (
                                        <MenuItem onClick={handleCloseNavMenu} component="a" href={page.link} target="_blank">
                                            <Typography textAlign="center" style={{ fontFamily: "'VCR OSD Mono', monospace" }}>
                                                {page.title}
                                            </Typography>
                                        </MenuItem>)
                                        : (<MenuItem onClick={handleCloseNavMenu} component={Link} to={page.link}><Typography textAlign="center" style={{ fontFamily: "'VCR OSD Mono', monospace" }}>{page.title}</Typography></MenuItem>)}
                                </>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
                        <img src="/assets/icon.webp" style={{ maxHeight: "2rem" }} alt="CHS Computer Club logo" />
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "VCR OSD Mono",
                            color: "white",
                            textDecoration: "none",
                            textTransform: "uppercase"
                        }}
                    >
                        CHS Comp Club
                    </Typography>

                    <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                        {pages.map((page) => (
                            <>
                                {page.out ? <Button onClick={handleCloseNavMenu} sx={{ my: 2, color: "white", display: "block" }} component="a" href={page.link} target="_blank">{page.title}</Button> : <Button onClick={handleCloseNavMenu} sx={{ my: 2, color: "white", display: "block" }} component={Link} to={page.link}>{page.title}</Button>}
                            </>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default TopBar;