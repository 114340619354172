import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Card, CardHeader, Grid, IconButton, Tooltip } from "@mui/material";


export default function GoogleFormEmbedCard(props: { title: string, formLink: string }) {
    return (
        <>
            <Grid item sx={{ width: "100%" }}>
                <Card sx={{ borderRadius: "32px" }}>
                    <CardHeader
                        sx={{ color: "white", textTransform: "uppercase", paddingRight: "2rem", paddingLeft: "2rem" }}
                        title={props.title}
                        action={
                            <>
                                <Button sx={{ display: { xs: "none", md: "inline-flex" } }} onClick={() => { window.open(props.formLink, "_blank") }} startIcon={<OpenInNewIcon />}>
                                    Open in New Tab
                                </Button>
                                <Tooltip title="Open in New Tab">
                                    <IconButton sx={{ display: { xs: "inline-flex", md: "none" } }} color="primary" onClick={() => { window.open(props.formLink, "_blank") }}>
                                        <OpenInNewIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    />
                    <iframe title={props.title} style={{ width: "100%", height: "100vh", border: 0, borderTopRightRadius: "32px", borderTopLeftRadius: "32px" }} src={props.formLink} />
                </Card>
            </Grid>
        </>
    )
}