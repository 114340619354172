import { Card, CardContent, CardHeader, Container, Grid } from "@mui/material";

import GoogleFormEmbedCard from "../Components/GoogleFormEmbedCard";
import TopImage from "../Components/TopImage";

export default function Donations() {
    const formLink = "https://docs.google.com/forms/d/e/1FAIpQLSfl1wfdDjC9bvPQLIw6U4exDBZo_g8Sb3pRiVYLUvupLyNpVw/viewform"
    return (
        <>
            <TopImage image="/assets/homepageBanner.webp" alt="Tech Help Header" />
            <Container maxWidth="lg" sx={{ "marginTop": "24px", marginBottom: "24px" }}>
                <Grid container spacing={3}>
                    <Grid item sx={{ width: "100%" }}>
                        <Card sx={{ borderRadius: "32px" }}>
                            <CardHeader
                                sx={{ color: "white", textAlign: "center", textTransform: "uppercase" }}
                                title="Why Donate?"
                            />
                            <CardContent style={{ color: "white", textAlign: "center", paddingTop: 0 }}>
                                <ul style={{ listStylePosition: "inside", padding: 0 }}>
                                    <li>TAX WRITEOFF!!!</li>
                                    <li>Gives us new tech to mess with</li>
                                    <li>Gets us hooked on your company's product. If we are used to it, that's likely what we will use later in life.</li>
                                </ul>
                            </CardContent>
                        </Card>
                    </Grid>
                    <GoogleFormEmbedCard title="Donation Form" formLink={formLink} />
                </Grid>
            </Container>
        </>
    )
}